/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import Helmet from 'react-helmet';
import { Toaster } from 'react-hot-toast';

import * as metadata from './metadata';
import { PageSpinner } from './staticComponents/spinner';

import { toastStyle } from './utils/entity_mapping';
import { routeParser } from './utils/helper';

const getTrackingPageComponent = () => {
  return import(/* webpackChunkName: 'tracking' */ './components/themes/qc');
};

const LoadableTracking = (subdomain, location) =>
  Loadable({
    loader: () => getTrackingPageComponent(subdomain, location),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableNotFound = subdomain =>
  Loadable({
    loader: () =>
      import(/* webpackChunkName: 'NotFound' */ './components/NotFound'),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const LoadableHome = subdomain =>
  Loadable({
    loader: () => import(/* webpackChunkName: 'home' */ './components/home'),
    loading() {
      return <PageSpinner subdomain={subdomain} />;
    }
  });

const App = ({ location, subdomain }) => {
  const routeInfo = routeParser(location);

  return (
    <div className="App">
      <Helmet
        title={metadata.title}
        meta={metadata.meta}
        link={metadata.link}
        script={metadata.script}
        noscript={metadata.noscript}
      />
      <Switch>
        <Route
          exact
          path="/page"
          component={
            routeInfo && routeInfo.isTrackingUrl
              ? LoadableTracking(subdomain, location)
              : LoadableHome(subdomain)
          }
        />
        <Route
          exact
          path="/:lang/page"
          component={
            routeInfo && routeInfo.isTrackingUrl
              ? LoadableTracking(subdomain, location)
              : LoadableHome(subdomain)
          }
        />
        <Route
          exact
          path="/tracking"
          component={LoadableTracking(subdomain, location)}
        />
        <Route
          exact
          path="/:lang/tracking"
          component={LoadableTracking(subdomain, location)}
        />
        <Route
          exact
          path="/en"
          component={LoadableTracking(subdomain, location)}
        />
        <Route
          exact
          path="/ar"
          component={LoadableTracking(subdomain, location)}
        />
        <Route
          exact
          path="/vi"
          component={LoadableTracking(subdomain, location)}
        />
        <Route
          exact
          path="/hi"
          component={LoadableTracking(subdomain, location)}
        />
        <Route
          exact
          path="/mr"
          component={LoadableTracking(subdomain, location)}
        />
        <Route
          exact
          path="/bn"
          component={LoadableTracking(subdomain, location)}
        />
        <Route
          exact
          path="/gu"
          component={LoadableTracking(subdomain, location)}
        />
        <Route
          exact
          path="/te"
          component={LoadableTracking(subdomain, location)}
        />
        <Route
          exact
          path="/ta"
          component={LoadableTracking(subdomain, location)}
        />
        <Route
          exact
          path="/id"
          component={LoadableTracking(subdomain, location)}
        />
        <Route
          exact
          path="/kn"
          component={LoadableTracking(subdomain, location)}
        />
        <Route
          exact
          path="/fr"
          component={LoadableTracking(subdomain, location)}
        />
        <Route
          exact
          path="/"
          component={LoadableTracking(subdomain, location)}
        />
        <Route component={LoadableNotFound(subdomain)} />
      </Switch>
      <Toaster
        toastOptions={{
          duration: 5000,
          className: 'custom-toast',
          style: {
            background: 'var(--base-toast-background-color)',
            color: 'var(--base-toast-color)'
          },
          error: {
            ...toastStyle.error
          }
        }}
      />
    </div>
  );
};
export default withRouter(App);
